.floppy {
    width: 100px;
    height: 100px;

    position: absolute;
    z-index: 11;
    overflow: hidden;
}

.floppy-backgrounds {
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
}
