.console-container {
    width: 8rem;

    display: flex;
    flex-direction: column;

    position: absolute;
    top: 10px;
    right: 10px;
}

/* LEDS */
.console-led-container {
    display: flex;
    flex-direction: row-reverse;

    width: 100%;
    height: 20px;

    position: absolute;
    right: 0;
    bottom: 0;
}

.console-led {
    width: 25px;
    background-color: #000;
}

.console-led.blink {
    animation: console-led-blink-anim 0.2s steps(2) both;
}

@keyframes console-led-blink-anim {
    0% {
        background-color: #000;
    }

    50% {
        background-color: #EAB543;
    }

    0% {
        background-color: #000;
    }
}


/* Floppy */
.console-floppy {
    width: 98px;
    position: absolute;

    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.console-eject {
    width: 20px;
    height: 100%;

    position: absolute;
    left: 8px;
    top: 0;
}

.console-eject.enabled {
    cursor: pointer;
}
