html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;

    margin: 0;

    word-wrap: break-word;

    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    font-family: 'Early GameBoy';
    display: flex;

    background-color: #101010;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

main {
    display: flex;

    width: 100%;
    height: 100%;
    position: relative;
}

* {
    user-select: none;
    box-sizing: border-box;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}

img {
    pointer-events: none;
}

a {
    text-decoration: none;
}

.hidden {
    display: none !important;
}

/*========================
  SCROLL BAR
*/
::-webkit-scrollbar {
    width: 3px;
    background: #080808;

    -webkit-appearance: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    width: 3px;

    height: 2px;
    min-height: 2px !important;

    background: rgb(185, 185, 185);
}
