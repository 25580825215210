.app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    color: rgb(238, 238, 238);
    z-index: 1;

    width: 100%;
}

.app-background-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpiPHXqlBoDfnALnyQTA4Vg1IDBYAALoXgGArXRQBz2BgAEGADTJQSAKWck1wAAAABJRU5ErkJggg==');
    background-position: 0 0, 0.6rem 0.6rem;
    background-size: 1.2rem 1.2rem;
    opacity: 0.3;

    animation: app-bg-anim 0.58s steps(3) infinite both;

    z-index: 0;
}

.app-header {
    color: white;
    font-size: 6rem;

    text-align: center;
    text-shadow: #000 6px 6px 0px;

    animation: app-header-anim 1s steps(2) alternate infinite both;
    position: relative;
}

@keyframes app-header-anim {
    0% {
        bottom: -20px;
    }

    100% {
        bottom: 20px;
    }
}

@keyframes app-bg-anim {
    0% {
        background-position: 0 0, 0.6rem 0.6rem;
    }
    100% {
        background-position: 0.6rem 0.6rem, 1.2rem 1.2rem;
    }
}
